import React, {useState, useEffect, useCallback} from 'react'
import {Spinner} from '@material-tailwind/react'
import { debounce } from 'lodash';
import toast, { Toaster } from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from '@heroicons/react/24/solid';

import { encodedAddressPrefix, tippingAddress, ownerAddress, royalty, tip, contentLength, serviceFeeRate, padding } from '../configs/constant';
import { Input } from '@material-tailwind/react'
import { formatAddress } from '../util/format-data'
import { getSignMintText, updatePayment, latestPayment, registerToken, getLatestMint, updateMint } from '../util/api'
import { getAsciiSum } from '../util/format-data';
import { getFeeRate, bytesToHex, buf2hex, textToHex, hexToBytes, getMempoolUtxos, loopTilAddressReceivesMoney, waitSomeSeconds, addressReceivedMoneyInThisTx, pushBTCpmt, calculateFee, getData, isValidTaprootAddress} from '../util/inscribe-util';
import { sendBtcTransaction } from 'sats-connect';
import FeeRateCard from '../components/FeeRateCard';
import { BitcoinNetworkType, signMessage, signTransaction } from 'sats-connect';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Slider,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter
} from "@material-tailwind/react";

import { useWallet, useWallets } from '@wallet-standard/react';
import { runesPaymentIndex, updateRunePayment, mintRuneStart } from '../util/api';
import { postUnisatTransaction } from '../util/api';

export default function Runes() {
  const { wallets } = useWallets();

  const SatsConnectNamespace = 'sats-connect:';

  const isSatsConnectCompatibleWallet = (wallet) => {
      return SatsConnectNamespace in wallet.features;
  }

  const wallet = useSelector(state => state.wallet);

  const { Address, Script, Signer, Tap, Tx } = window.tapscript;

  const [loading, setLoading] = useState(false);

  const handleMint = async (randomKey, fundAddress) => {
    let transactionData;
    for(let i = 0; i< 5; i++){
      transactionData = await getMempoolUtxos(fundAddress);
      if ( transactionData != undefined && transactionData.length >= 1){
        break;
      }
      await waitSomeSeconds(3);
    }

    if ( transactionData != undefined && transactionData.length >= 1){
      let paramDatas = [];
      for(let i = 0; i < 1; i++) {
        paramDatas.push({
          txid : transactionData[0].txid,
          vout : transactionData[0].vout,
          value : transactionData[0].value,
          fundAddress : fundAddress
        })
      }

      while(true) {
        if (paramDatas.length == 0) break;
        let resDatas = await mintRuneStart(randomKey, paramDatas);
        paramDatas = [];
        for(let resData of resDatas) {
          let txid = "";
          while(true) {
            await waitSomeSeconds(3);
            txid = await postUnisatTransaction(resData.hex);
            if (txid.length == 64) break;
            await waitSomeSeconds(10);
            txid = await pushBTCpmt(resData.hex);
            if (txid.length == 64) break;
          }
          if (resData.value > 0) {
            paramDatas.push({
              txid : txid,
              vout : 1,
              value : resData.value,
              fundAddress : resData.fundAddress
            })
          }
        }
      }
    }
    else {
      await updateRunePayment(randomKey, fundAddress);
    }
  }

  const handleRecover = async () => {
    setLoading(true);
    while(true){
      let result = await runesPaymentIndex();
      console.log(result);
      for(let data of result) {
        await handleMint(data.randomKey, data.fundAddress);
      }
      await sleep(10000);
    }
    setLoading(false);
  }

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  return (
    <div className="text-white mt-[65px] w-full max-w-[1500px] md:px-7 px-2 flex flex-col justify-center items-center mb-10 min-h-[600px]">
      <div className="w-[400px]">
        <Button
          onClick={() => {
            handleRecover();
          }}
          fullWidth
        >Recover Runes</Button>
      </div>
      {
        loading ? <Spinner className="h-16 w-16 text-gray-900/50 fixed" color="pink"/> : <></>
      } 
      <Toaster 
        position="top-right"
      />
    </div>
  )
}
